
import cookie from 'react-cookie';

// let user = {};
// if ( cookie.load('user') ) {
//   user = cookie.load('user').user;
// }

// ***************************
// Port/Device Initialization
// ***************************

// // Filter on devices with the Arduino Uno USB Vendor/Product IDs.
// const filters = [
// { usbVendorId: 0x0403, usbProductId: 0x6015 }
// ];

// // Prompt user to select device.
// const port = await navigator.serial.requestPort({ filters });
// const { usbProductId, usbVendorId } = port.getInfo();

let port;
let keepReading=true;

// let keepReading=true;
// ******************
// Connect to Device
// ******************

export async function ConnectToDevice() {
      
    // // Filter on devices with the Arduino Uno USB Vendor/Product IDs.
    // const filters = [
    // { usbVendorId: 0x0403, usbProductId: 0x6015 }
    // ];
    
    // // Prompt user to select device.
    // const port = await navigator.serial.requestPort({ filters });
    // const { usbProductId, usbVendorId } = port.getInfo();
    
    const filters = [
    { usbVendorId: 0x0403, usbProductId: 0x6015 }
    ];
    port = await navigator.serial.requestPort({filters});
    // const { usbProductId, usbVendorId } = port.getInfo();

    // // Wait for the serial port to open.
    //     // Default values
    //         // dictionary SerialOptions {
    //         // required [EnforceRange] unsigned long baudRate;
    //         // [EnforceRange] octet dataBits = 8;
    //         // [EnforceRange] octet stopBits = 1;
    //         // ParityType parity = "none";
    //         // [EnforceRange] unsigned long bufferSize = 255;
    //         // FlowControlType flowControl = "none";
    //         // };
    await port.open({ baudRate: 19200,
      dataBits: 8,
      stopBits: 1,
      parity: "none",
      flowControl: "none",
      bufferSize: 11
    });

    console.log('Test Connection');
    
    // const reader = port.readable.getReader();

    // console.log('Connected --> port',port);
    // console.log('Connected --> port.readable',port.readable);
    // console.log('Connected --> reader',reader);
    
    return true;
}


// ***************************
// Close connection to device
// ***************************

export async function DisconnectDevice() {

  console.log('DisconnectDevice')
    
    //     // Filter on devices with the Arduino Uno USB Vendor/Product IDs.
    // const filters = [
    // { usbVendorId: 0x0403, usbProductId: 0x6015 }
    // ];
    
    // // Prompt user to select device.
    // const port = await navigator.serial.requestPort({ filters });
    // const { usbProductId, usbVendorId } = port.getInfo();

// CLOSE CONNECTION
await port.close();

}


// ********************
// Start Reading Data
// ********************

export async function StartReadData() {

    let allValues = [];
    let currentValue = [];
    const series = [];
    // const timearray = [];
    let res;
    let result;
    // let gain;
    cookie.save('projectSeries', [0]);

    // Read Data
    while (port.readable) {
      const reader = port.readable.getReader();
      console.log('StartReadData --> port',port);
      console.log('StartReadData --> port.readable',port.readable);
      console.log('StartReadData --> reader',reader);
      try {
          /* eslint-disable no-constant-condition */
          while (true) {

            if(keepReading===false)
            {
                reader.cancel();
                keepReading=true;
            }
            /* eslint-disable no-await-in-loop */
            const { value, done } = await reader.read();
            if (done) {
                console.log('Done');
                // Allow the serial port to be closed later.
                break;
            }
            if (value) {

                
              for (let i = 0; i < value.length ; i += 1) {

                allValues.push(value[i]);
                
                currentValue = [];
                
                if(allValues.length === 10) {
                    
                  currentValue.push(allValues);
                  allValues = [];

                  if(currentValue[0][0] === 170) {

                    // Measurement Mode

                    // // Calculations
                    // let sum = currentValue[0][4] + (256*currentValue[0][5]);

                    // // console.log("sum initial 170", sum );

                    // if (sum <= 4) {
                    //   sum = 0;
                    // }

                    // // console.log("sum after if check", sum );

                    // if(currentValue[0][3] === 0){
                    //   gain = 1;
                    // } else if (currentValue[0][3] === 1) {
                    //   gain = 2;
                    // }  else if (currentValue[0][3] === 2) {
                    //   gain = 4;
                    // }  else if (currentValue[0][3] === 3) {
                    //   gain = 5;
                    // }  else if (currentValue[0][3] === 4) {
                    //   gain = 8;
                    // }  else if (currentValue[0][3] === 5) {
                    //   gain = 10;
                    // }  else if (currentValue[0][3] === 6) {
                    //   gain = 16;
                    // }  else if (currentValue[0][3] === 7) {
                    //   gain = 32;
                    // }

                    // if(currentValue[0][2] === 0){
                    //   res = -sum/1023*3.3/gain;  // Calibration gain should be added
                    //   if ( user.linNord ) {
                    //     res *= user.linNord;
                    //   }
                    // } else if (currentValue[0][2] === 1) {
                    //   res = sum/1023*3.3/gain;   // Calibration gain should be added
                    //   if ( user.linSouth ) {
                    //     res *= user.linSouth;
                    //   }
                    // }

                    // result = res.toFixed(3);

                     // Create an ArrayBuffer with a size in bytes
                     const buffer = new ArrayBuffer(4);
                     const bytes = new Uint8Array(buffer);
                     bytes[0] = currentValue[0][3];
                     bytes[1] = currentValue[0][4];
                     bytes[2] = currentValue[0][5];
                     bytes[3] = currentValue[0][6];
                     
                     console.log('bytes',bytes);

                    //  TEST 
                    if(currentValue[0][2] === 0){
                      
                      console.log("South Pole", currentValue[0][2]);
                      const resBuff = new DataView(buffer);
                      res = -resBuff.getFloat32(0, true);
                      console.log(res);

                    } else if (currentValue[0][2] === 1) {
                      console.log("Nord Pole", currentValue[0][2]);
                      const resBuff = new DataView(buffer);
                      res = resBuff.getFloat32(0, true);
                      console.log(res);
                    }


                    result = res.toFixed(3);
                    series.push(result);
                    // console.log('series',series);
                    cookie.save('projectSeries', series);
                    // console.log('cookie.load series', cookie.load('projectSeries'));

                    // Compute Time

                    // const today = new Date();
                    // const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + "." + today.getMilliseconds();
                    // console.log('time',time,'result',result);
                    // timearray.push(time);
                    // console.log('timearray',timearray);

                  } else if(currentValue[0][0] === 238){
                    // Calibration mode
                    console.log('Calbration mode currentValue', currentValue[0]);

                     // create an ArrayBuffer with a size in bytes
                     const buffer = new ArrayBuffer(4);
                     const bytes = new Uint8Array(buffer);
                     bytes[0] = currentValue[0][5];
                     bytes[1] = currentValue[0][6];
                     bytes[2] = currentValue[0][7];
                     bytes[3] = currentValue[0][8];
                     
                     console.log('bytes',bytes);

                    if (currentValue[0][1] === 1) {
                      console.log("no magnet", currentValue[0][1]);


                    } else if (currentValue[0][1] === 2) {
                      console.log("calibration nord", currentValue[0][1]);
                      const linNord = new DataView(buffer);
                      console.log(linNord.getFloat32(0, true));
                      // return linNord;
                      
                      // const linNord = parseFloat( currentValue[0][5] + (256*currentValue[0][6]) + (65536*currentValue[0][7]) + (16777216*currentValue[0][8]) );

                    } else if (currentValue[0][1] === 3) {
                      
                      console.log("calibration south", currentValue[0][1]);
                      const linSouth = new DataView(buffer);
                      console.log(linSouth.getFloat32(0, true));
                      // return linSouth;

                      // const linSouth = parseFloat( currentValue[0][5] + (256*currentValue[0][6]) + (65536*currentValue[0][7]) + (16777216*currentValue[0][8]) );

                    } 


                  }
                }
              }
            }
        }
      } catch (error) {
            // TODO: Handle non-fatal read error.
            console.log('Error',error);
      }
      finally {
         // Release the lock on port and exit function
         console.log('In Finallly');
         reader.releaseLock();

        /* eslint-disable no-unsafe-finally */
        break;
      }
    }
}

// ********************
// Stop Reading Data
// ********************

export async function StopReadData() {
  console.log('stop reading data');
  // const reader = port.readable.getReader();
  // console.log('StartReadData --> port',port);
  // console.log('StartReadData --> port.readable',port.readable);
  // console.log('StartReadData --> reader',reader);
  keepReading =false;
}

// ********************
// Write Data
// ********************


export async function WriteDataNoMagnet() {
  // WRITE DATA
   const writer = port.writable.getWriter();
   const data = new Uint8Array([72]); 
   console.log('data 72 ', data);
   await writer.write(data);
  // Allow the serial port to be closed later.
   writer.releaseLock();
}


export async function WriteDataNorthPole() {
  // WRITE DATA
   const writer = port.writable.getWriter();
   const data = new Uint8Array([73]); 
   console.log('data 73 ', data);
   await writer.write(data);
  // Allow the serial port to be closed later.
   writer.releaseLock();
}


export async function WriteDataSouthPole() {
  // WRITE DATA
  const writer = port.writable.getWriter();
  const data = new Uint8Array([74]); 
  console.log('data 74 ', data);
  await writer.write(data);
 // Allow the serial port to be closed later.
  writer.releaseLock();
}

