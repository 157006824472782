import axios from 'axios';
import { saveAs } from 'file-saver';
import XLSX from 'xlsx';
import { downloadOptions, errorHandler } from './index';
import { API_URL } from '../config';

// import types from '../actions/types';
// import { actions } from '../reducers/app';

export function fileDownload(filename) {
  return async (dispatch) => {
    try {
		const response = await axios(downloadOptions(
			API_URL + '/downloadFile/' + filename, {}, 'post', '', 'base64'
		));

		const responseOK = response && response.status === 200 && response.statusText === 'OK';
		if (responseOK) {
			const fileName = response.headers['content-disposition'].substring(21, 52);
			const blob = new Blob([response.data], { type: "application/pdf;charset=utf-8" })

			saveAs(blob, fileName);
	  	}

    } catch (error) {
      console.warn('Error:', error);
      dispatch(errorHandler(error));
    }
  };
}

function s2ab(s) {
	const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
	const view = new Uint8Array(buf);  // create uint8array as viewer

	for (let i = 0; i < s.length; i += 1) {
		// eslint-disable-next-line no-bitwise
		view[i] = s.charCodeAt(i) & 0xFF; // convert to octet
	}
	return buf;
}

export function exportToXLSX(items, fileTitle) {
	const wb = XLSX.utils.book_new();
	const title = fileTitle.replaceAll('/', '_');
	const wsName = title;
	const ws = XLSX.utils.json_to_sheet(items, { origin: 'A1', skipHeader: true });
	XLSX.utils.book_append_sheet(wb, ws, wsName);

	const bin = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
	saveAs(new Blob([s2ab(bin)], { type: 'application/octet-stream' }), title + '.xlsx');
}

export function exportToCSV(items, fileTitle) {
	const workSheet = XLSX.utils.json_to_sheet(items, { origin: 'A1', skipHeader: true });
	const csv = XLSX.utils.sheet_to_csv(workSheet);

	saveAs(new Blob([s2ab(csv)], { type: 'application/octet-stream' }), fileTitle.replaceAll('/', '_') + '.csv');
}
  